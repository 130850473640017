import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Box, Text, Image } from 'rebass';
import { StyleObject } from 'types/styles';
import { Colors } from 'constants/Colors';
import { useRouter } from 'next/router';
import { LinkWrapper } from 'components/common/LinkWrapper';
import { useRecoilValue } from 'recoil';
import { userState } from 'src/recoil/UserState';
import { StatApi } from 'utils/Api/StatApi';
import { isPartyTime } from 'utils/MeetupUtil';
import { LineBannerWithTextAndBg } from './LineBannerWithTextAndBg';

const ROUTE_MAP = [
  {
    isNew: false,
    name: '홈',
    path: '/',
  },
  {
    isNew: true,
    name: '커뮤니티 후기',
    path: '/community-reviews',
  },
  {
    isNew: false,
    name: '정기 모임',
    path: '/salons',
  },
  {
    isNew: false,
    name: '이벤트',
    path: '/events',
  },
  {
    isNew: false,
    name: '소모임',
    path: '/community',
  },
];

const textBannersInfo = [
  {
    link: 'https://bit.ly/3Q75dFn',
    text: `[7,202명 참여중] 이젠 <b>채팅</b>에서도 취향으로 만나요! →`,
  },
  {
    link: 'https://bit.ly/3sblNMb',
    text: `[7,202명 참여중] 도시남녀들의 <b>취향 단톡방</b> 커뮤니티? →`,
  },
];

interface NavProps {
  showLineBanner?: boolean;
  onCloseLineBanner?: () => void;
  isNavTransition?: boolean;
}

export const Nav = ({
  showLineBanner = false,
  onCloseLineBanner,
  isNavTransition = false,
}: NavProps) => {
  const user = useRecoilValue(userState);
  const [showTopNav, setShowTopNav] = useState(true);
  const [realtimeUserCount, setRealtimeUserCount] = useState(undefined);
  const [randomIndex, setRandomIndex] = useState(0);
  const router = useRouter();

  const onClickProfile = useCallback(async () => {
    await router.push('/my');
  }, [router]);

  const onClickBookmark = useCallback(async () => {
    await router.push('/bookmarks');
  }, [router]);

  const userProfileImage = useMemo(() => {
    if (user && user.profileImageUrl) {
      return user.profileImageUrl;
    }
    return '/profile_default.svg';
  }, [user]);

  const getRealtimeUserCount = useCallback(async () => {
    const res = await StatApi.getRealtimeUserCount();
    setRealtimeUserCount(res.realtimeUserCount);
  }, []);

  useEffect(() => {
    if (isPartyTime(new Date())) {
      getRealtimeUserCount();
    }
  }, [getRealtimeUserCount]);

  // useEffect(() => {
  //   if (isNavTransition) {
  //     const handleScroll = () => {
  //       if (window.scrollY > 600) {
  //         setShowTopNav(false);
  //       } else {
  //         setShowTopNav(true);
  //       }
  //     };
  //     window.addEventListener('scroll', handleScroll);
  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //     };
  //   }
  //   const randomBannerIndex = Math.floor(Math.random() * textBannersInfo.length);
  //   setRandomIndex(randomBannerIndex);
  // }, [isNavTransition]);

  return (
    <>
      <Box sx={styles.navContainer}>
        <Flex sx={styles.container}>
          {/* {showLineBanner && (
            <LineBannerWithTextAndBg
              text={textBannersInfo[randomIndex].text}
              link={textBannersInfo[randomIndex].link}
              onClickClose={onCloseLineBanner}
              bgStyle={{
                background:
                  'linear-gradient(90deg, rgba(255,194,59,1) 20%, rgba(255,166,49,1) 60%, rgba(248,148,0,1) 100%)',
              }}
              textStyles={{
                color: '#270707',
              }}
              filterSvg='url("/nnnoise-3.svg")'
            />
          )} */}
          <Flex
            sx={{
              ...styles.topWrapper,
              height: showTopNav ? ['48px', '72px'] : ['0px', '72px'],
            }}>
            <LinkWrapper
              href="/"
              sx={{ ...styles.logoWrapper, display: showTopNav ? 'unset' : ['none', 'unset'] }}>
              <Image sx={styles.logo} src="/nfyg_logo_new.svg" />
              <Image sx={styles.logoSub} src="/2024-summer-season.png" />
            </LinkWrapper>
            {isPartyTime(new Date()) && realtimeUserCount && showTopNav && (
              <Flex sx={styles.liveTextContainer}>
                <Flex sx={styles.redIconOutside} />
                <Flex sx={styles.redIconInside} />
                <Flex sx={{ minWidth: '140px' }}>
                  <Text sx={styles.liveText1}>수요일은 신규모임 오픈일🎉</Text>
                  <Text sx={styles.liveText2}>지금 {realtimeUserCount}명 접속중🏝️⛱️</Text>
                </Flex>
              </Flex>
            )}
            <Flex
              sx={{
                ...styles.heartWrapper,
                display: showTopNav ? 'flex' : ['none !important', 'flex !important'],
              }}
              onClick={onClickBookmark}>
              <Image sx={styles.heartIcon} src="/heart_gray.svg" />
            </Flex>
            <Flex
              sx={{
                ...styles.profileWrapper,
                display: showTopNav ? 'flex' : ['none !important', 'flex !important'],
              }}
              onClick={onClickProfile}>
              <Image sx={styles.profileImage} src={userProfileImage} />
            </Flex>
          </Flex>
          <Flex sx={styles.itemsWrapper}>
            {ROUTE_MAP.map(route => (
              <Flex
                sx={{
                  ...styles.item,
                  borderBottom: router.pathname === route.path ? `3px solid ${Colors.gray11}` : '',
                }}
                key={`nav-${route.path}`}>
                <LinkWrapper href={route.path}>
                  <Text
                    sx={{
                      ...styles.navItemText,
                      color:
                        router.asPath === route.path
                          ? Colors.gray11
                          : [Colors.gray11, Colors.nfygNavy],
                      fontWeight: router.pathname === route.path ? [700, 700] : [700, 600],
                      mt: router.pathname === route.path ? '3px' : '0px',
                    }}>
                    {route.name}
                    {!!route.isNew && <Image sx={styles.newIcon} src="/nav_new.svg" />}
                  </Text>
                </LinkWrapper>
              </Flex>
            ))}
            <Text sx={styles.membersOnly}>Members only</Text>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

const styles: StyleObject = {
  container: {
    alignItems: 'center',
    bg: 'white',
    borderBottom: ['none', `1px solid #E4E7EC`],
    boxShadow: ['0px 2px 12px rgba(0, 0, 0, 0.08)', 'none'],
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    transition: 'all 0.2s ease 0s',
    width: '100%',
    zIndex: 9999,
  },
  heartIcon: {
    cursor: 'pointer',
    height: ['22px', '24px'],
    width: ['22px', '24px'],
  },
  heartWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    ml: 'auto',
    position: 'relative',
  },
  item: {
    ':hover': {
      color: Colors.gray11,
      fontWeight: 700,
    },
    alignItems: 'center',
    color: Colors.gray82,
    flex: '0 0 auto',
    height: '100%',
    mr: ['10px', '25px'],
    pb: ['0px', '10px'],
    position: 'relative',
    px: '5px',
    transition: 'all 0.3s ease 0s',
    width: 'fit-content',
  },
  itemsWrapper: {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: ['48px', '48px'],
    maxHeight: ['48px', '48px'],
    maxWidth: '1000px',
    minHeight: ['48px', '48px'],
    overflowX: 'scroll',
    px: ['20px', '0px'],
    width: '100%',
  },
  liveText1: {
    '@keyframes fade': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 0,
      },
      '33%': {
        opacity: 1,
      },
      '66%': {
        opacity: 0,
      },
    },
    animation: `fade 6s infinite`,
    color: '#60636C',
    fontSize: ['12px', '13px'],
    fontWeight: 600,
    lineHeight: '17px',
    opacity: 0,
    position: 'absolute',
    top: '-3px',
    transition: 'opacity 1s',
    width: '100%',
  },
  liveText2: {
    '@keyframes fade': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 0,
      },
      '33%': {
        opacity: 1,
      },
      '66%': {
        opacity: 0,
      },
    },
    animation: `fade 6s infinite`,
    animationDelay: '3s',
    color: '#60636C',
    fontSize: ['12px', '13px'],
    fontWeight: 600,
    lineHeight: '17px',
    opacity: 0,
    position: 'absolute',
    top: '-3px',
    transition: 'opacity 1s',
    width: '100%',
  },
  liveTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    ml: ['10px'],
    mt: ['5px', '-4px'],
    position: 'relative',
  },
  logo: {
    height: 'auto',
    mb: ['0px', '4px'],
    mt: ['8px', '0px'],
    width: ['71px', '84px'],
  },
  logoSub: {
    height: 'auto',
    mb: ['0px', '3px'],
    ml: '2px',
    width: ['20px', '24px'],
  },
  logoWinter: {
    height: 'auto',
    mt: ['4px', '0px'],
    pt: '0px',
    width: ['96px', '108px'],
  },
  logoWrapper: {
    mx: ['0px', '0px'],
    position: ['relative', 'relative'],
  },
  membersOnly: {
    color: Colors.gray99,
    fontSize: ['8px', '10px'],
    fontWeight: 400,
    mb: ['4px', '0px'],
    minWidth: 'fit-content',
    ml: ['-11px', '-24px'],
    pb: ['0px', '10px'],
  },
  navContainer: {
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
    zIndex: 9999,
  },
  navItemText: {
    ':hover': {
      color: Colors.gray11,
      fontWeight: 700,
    },
    color: Colors.gray82,
    fontSize: ['14px', '16px'],
    transition: 'all 0.2s ease 0s',
  },
  newIcon: {
    height: '15px',
    mb: '-2px',
    ml: '4px',
    width: '15px',
  },
  profileImage: {
    borderRadius: ['13px', '20px'],
    cursor: 'pointer',
    height: ['26px', '36px'],
    width: ['26px', '36px'],
  },
  profileWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    ml: ['24px', '36px'],
  },
  redIconInside: {
    bg: '#E25345',
    borderRadius: '50%',
    height: '5px',
    left: '2.5px',
    position: 'absolute',
    top: '2.5px',
    width: '5px',
  },
  redIconOutside: {
    bg: '#E25345',
    borderRadius: '50%',
    height: '10px',
    mr: '4px',
    opacity: 0.2,
    width: '10px',
  },
  topWrapper: {
    alignItems: 'center',
    maxWidth: '1000px',
    position: 'relative',
    pt: ['0px', '12px'],
    px: ['20px', '0px'],
    transition: 'all 0.2s ease-in-out 0s',
    width: '100%',
  },
};
