/* eslint-disable camelcase */
import { CookieKeys } from "constants/Constants";
import { getAuth, GoogleAuthProvider, signInWithPopup, UserCredential } from "firebase/auth";
import { AuthApi, RegisterUserRequestBody } from "utils/Api/AuthApi";
import { BaseApi } from "utils/Api/BaseApi";
import {
  clearCookies,
  getTempUserFromCookie,
  setAuthTokenCookies,
  setAuthTokenLocalStorage,
  setTempUserCookie,
  setUserCookie,
  setUserLocalStorage
} from "utils/AuthUtil";
import axios from "axios";
import qs from "qs";
import { defaultAlert, defaultToast } from "utils/Alert";
import { dayjsKr } from "utils/TextUtils";
import axiosRetry from "axios-retry";
import { LoginPlatforms, logPurchaseEventAction, LogPurchaseProcessTargets, MixpanelEvents } from "utils/Analytics";
import { MixpanelTracker } from "utils/Mixpanel";
import { initializeFirebase } from "./initialize";

export const AUTH_PROVIDER_FIREBASE = "firebase";
export const AUTH_PROVIDER_KAKAO = "kakao";

initializeFirebase();

const auth = getAuth();
auth.languageCode = "kr";
const googleAuthProvider = new GoogleAuthProvider();

const kakaoAuthApiClient = axios.create({
  baseURL: "https://kauth.kakao.com",
  timeout: 20000
});
axiosRetry(kakaoAuthApiClient, { retries: 3 });
const kakaoApiClient = axios.create({
  baseURL: "https://kapi.kakao.com",
  timeout: 20000
});
axiosRetry(kakaoApiClient, { retries: 3 });

export const signInWithGoogle = () => {
  signInWithPopup(auth, googleAuthProvider).then(async (result: UserCredential) => {
    clearCookies([CookieKeys.TEMP_REGISTER]);

    const idToken = await auth.currentUser.getIdToken(true);
    const { user } = result;
    const tempUser = {
      email: user.email,
      nickname: user.displayName,
      profileImageUrl: user.photoURL
    };

    try {
      const res = await AuthApi.signIn(AUTH_PROVIDER_FIREBASE, idToken);

      const cookiedUser = {
        ...res.user,
        introduction: null
      };
      setTempUserCookie(cookiedUser, AUTH_PROVIDER_FIREBASE, idToken, false);
      if (!res.user.phoneNumber) {
        defaultToast(
          `넷플연가에 돌아오신 것을 환영해요, ${res.user.nickname}님!\n계속하기에 앞서, 추가적인 정보 입력이 필요해요. 잠시만 시간을 내주시겠어요?`
        );
        window.location.href = `/register?redirect=${window.location.pathname}`;
      } else {
        setAuthTokenCookies(res.tokens.accessToken, res.tokens.refreshToken);
        setUserCookie(cookiedUser);

        setAuthTokenLocalStorage(res.tokens.accessToken, res.tokens.refreshToken);
        setUserLocalStorage(cookiedUser);

        BaseApi.setToken(res.tokens);

        window.location.reload();
      }
    } catch (e) {
      setTempUserCookie(tempUser, AUTH_PROVIDER_FIREBASE, idToken, true);
      if (e?.response?.status === 400) {
        MixpanelTracker.trackEvent(MixpanelEvents.SIGNUP_STARTED, {
          login_method: LoginPlatforms.GOOGLE
        });

        window.location.href = `/register?redirect=${window.location.pathname}`;
      }
    }
  });
};

export const signOutWithGoogle = async () => {
  await auth.signOut();
};

export const signOutWithKakao = async () => {
  const tempUser = getTempUserFromCookie();
  if (tempUser?.provider === AUTH_PROVIDER_KAKAO && tempUser?.providerToken) {
    return new Promise((resolve, reject) => {
      try {
        kakaoApiClient.post("/v1/user/logout", null, {
          headers: {
            Authorization: `Bearer ${tempUser?.providerToken}`
          }
        });
        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  }
  return false;
};

export const signInWithKakao = () => {
  window.Kakao.Auth.authorize({
    redirectUri: `${window.location.origin}/my`,
    state: window.location.href
  });
};

export const getKakaoAccessToken = async (kakaoCode: string) => {
  const responseToken = await kakaoAuthApiClient.post(
    "/oauth/token",
    qs.stringify({
      client_id: process.env.KAKAO_RESTAPI_KEY,
      code: kakaoCode,
      grant_type: "authorization_code",
      redirect_uri: `${window.location.origin}/my`
    })
  );
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return responseToken.data.access_token;
};

export const proceedKakaoLogin = async kakaoCode => {
  // FIXME: 에러 처리
  const kakaoAccessToken = await getKakaoAccessToken(kakaoCode);

  try {
    const res = await AuthApi.signIn(AUTH_PROVIDER_KAKAO, kakaoAccessToken);
    const cookiedUser = {
      ...res.user,
      introduction: null
    };
    setTempUserCookie(cookiedUser, AUTH_PROVIDER_KAKAO, kakaoAccessToken, false);
    setAuthTokenCookies(res.tokens.accessToken, res.tokens.refreshToken);
    setUserCookie(cookiedUser);

    setAuthTokenLocalStorage(res.tokens.accessToken, res.tokens.refreshToken);
    setUserLocalStorage(cookiedUser);

    BaseApi.setToken(res.tokens);
    window.location.reload();
  } catch (loginError) {
    if (loginError?.response?.status === 400) {
      MixpanelTracker.trackEvent(MixpanelEvents.SIGNUP_STARTED, {
        login_method: LoginPlatforms.KAKAO
      });

      const [kakaoUserInfo, consentResults] = await Promise.all([
        kakaoApiClient.get("/v2/user/me", {
          headers: {
            Authorization: `Bearer ${kakaoAccessToken}`
          }
        }),
        kakaoApiClient.get("/v1/user/service/terms", {
          headers: {
            Authorization: `Bearer ${kakaoAccessToken}`
          }
        })
      ]);

      // eslint-disable-next-line @typescript-eslint/naming-convention,camelcase
      const { allowed_service_terms } = consentResults.data;
      // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
      const { properties, kakao_account } = kakaoUserInfo.data;
      const userRequestBody: RegisterUserRequestBody = {
        birthday: dayjsKr(String(kakao_account.birthyear) + String(kakao_account.birthday)).format(
          "YYYY-MM-DD"
        ),
        email: kakao_account.email,
        gender: kakao_account.gender?.toLowerCase(),
        marketingConsent: allowed_service_terms.some(term => term.tag === "marketingConsent"),
        name: kakao_account.name,
        nickname:
          kakao_account.name.length > 1 ? kakao_account.name.substring(1) : properties.nickname,
        phoneNumber: kakao_account.phone_number?.startsWith("+82")
          ? `0${kakao_account.phone_number?.split(" ")[1]}`.replace(/-/g, "")
          : "",
        privacyPolicyConsent: allowed_service_terms.some(
          term => term.tag === "privacyPolicyConsent"
        ),
        profileImageUrl: properties.profile_image,
        provider: AUTH_PROVIDER_KAKAO,
        providerToken: kakaoAccessToken,
        servicePolicyConsent: allowed_service_terms.some(
          term => term.tag === "servicePolicyConsent"
        )
      };

      try {
        const res = await AuthApi.registerUser(userRequestBody);
        logPurchaseEventAction(LogPurchaseProcessTargets.SIGN_UP);

        const cookiedUser = {
          ...res.user,
          introduction: null
        };
        setTempUserCookie(cookiedUser, AUTH_PROVIDER_KAKAO, kakaoAccessToken, false);
        setAuthTokenCookies(res.tokens.accessToken, res.tokens.refreshToken);
        setUserCookie(cookiedUser);
        setAuthTokenLocalStorage(res.tokens.accessToken, res.tokens.refreshToken);
        setUserLocalStorage(cookiedUser);

        BaseApi.setToken(res.tokens);

        const urlParams = new URLSearchParams(window.location.search);
        const stateValue = urlParams.get("state");
        window.location.href = `/my/profile?redirect=${stateValue}`;
      } catch (userRegisterError) {
        if (userRegisterError?.response?.status === 400) {
          if (await defaultAlert(userRegisterError.response?.data?.message)) {
            window.location.reload();
          }
        }
        if (await defaultAlert("로그인 과정에서 오류가 발생했어요. 넷플연가에 문의해 주세요.")) {
          window.location.reload();
        }
      }
    } else if (await defaultAlert("로그인 과정에서 오류가 발생했어요. 넷플연가에 문의해 주세요.")) {
      window.location.reload();
    }
  }
};

export const getIdToken = async () => auth.currentUser?.getIdToken(true);
